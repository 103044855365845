import React, {Component, useContext, useEffect, useState} from 'react'
import '../../style/Admin/OverallScannerViewPage.css'
// import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
// import paginationFactory from 'react-bootstrap-table2-paginator';
import JSZip from 'jszip'
import JSZipUtils from 'jszip-utils'
// import BootstrapTable from 'react-bootstrap-table-next';
import saveAs from 'file-saver';
import axios from 'axios';
import Config from '../../services/Config';

import ScrollButton from '../../components/ScrollButton'
// import PDF from "../../services/PDF";
// import filterFactory from 'react-bootstrap-table2-filter';
// import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
// import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import {useTranslation} from "react-i18next";
import {BackendContext} from "../../machines/BackendMachine";
import {useActor} from "@xstate/react";
import {BootstrapTable} from "react-bootstrap-table"
import GBigLabel from "../../RemasterComponents/GBigLabel";
import metaLogo from "../../assets/meta.png";
import moment from "moment";
import http from "http";
import GSmallLabel from "../../RemasterComponents/GSmallLabel";
import PDF from "../../services/PDF";
import CSV from "../../services/CSV";
import SF from "../../services/SEARCH_FILTER";
// import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
// import number from 'react-bootstrap-table2-filter/lib/src/components/number';
// const { ExportCSVButton } = CSVExport;

const OverallScannerViewPage = () => {

    // axios.post(`${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/doctors/${BackendCurrent.context.profile.userId}/TestData`, body, )

    const {t, i18n} = useTranslation()
    const BEContext = useContext(BackendContext)
    let BackendCurrent, BESend;
    [BackendCurrent, BESend] = useActor(BEContext);


    const [data, setData] = useState([])
    const [logs, setLogs] = useState([])
    const [configs, setConfigs] = useState([])
    const [toDate, setToDate] = useState(new Date())
    const [fromDate, setFromDate] = useState(new Date())
    const [selectedDevice,SetSelectedDevice] = useState(null)
    useEffect(() => {
        getDevicesData();
    }, [])
    const formatDate = (date) => {
        return date.setHours(0, 0, 0, 0);
    }
    const handleSearchChange = (row, val) => {
        const _data = data;
        const d = _data.indexOf(row);
        const filteredTests = data[d].tests

        _data[d].filteredTests = filteredTests.filter(x => x.TestID.indexOf(val.target.value) !== -1);
        setData(_data);
    }
    const handleFromChange = (row, date) => {

        const filteredTests = data.filter(x => x.DeviceID === row.DeviceID)[0].tests.filter(x => (OverallScannerViewPage.formatDate(new Date(x.Start_scan.DateLocal)) >= OverallScannerViewPage.formatDate(new Date(date))) && (OverallScannerViewPage.formatDate(new Date(x.Start_scan.DateLocal)) <= OverallScannerViewPage.formatDate(new Date(toDate))));
        const _data = data;
        const d = _data.indexOf(row);
        _data[d].filteredTests = filteredTests;
        setData(_data)
        setFromDate(OverallScannerViewPage.formatDate(new Date(date)))
    }
    const handleToChange = (row, date) => {
        const filteredTests = data.filter(x => x.DeviceID === row.DeviceID)[0].tests.filter(x => (OverallScannerViewPage.formatDate(new Date(x.Start_scan.DateLocal)) <= OverallScannerViewPage.formatDate(new Date(date))) && (OverallScannerViewPage.formatDate(new Date(x.Start_scan.DateLocal)) >= OverallScannerViewPage.formatDate(new Date(fromDate))));
        const _data = data;
        const d = _data.indexOf(row);
        _data[d].filteredTests = filteredTests;
        setToDate(OverallScannerViewPage.formatDate(new Date(date)))
        setData(_data)
    }
    const getDevicesData = () => {
        axios.get(`${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/admins/${BackendCurrent.context.profile.userId}/overallScanner`, {headers: {'Authorization': BackendCurrent.context.authToken}})
            .then(async (response) => {
                // console.log('response '+ (new Date()));
                response.data.forEach(device => {
                    device['tests'] = [];
                    device['filteredTests'] = [];
                });
                setData(response.data);
            })
            .catch(async (error) => {
                console.log(error);
            });
    }
    const getConfigsByDevice = (value) => {
        SetSelectedDevice(value)
        axios.get(`${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/general/${BackendCurrent.context.profile.userId}/getiniFile/`+value, {headers: {'Authorization': BackendCurrent.context.authToken}})
            .then(async (response) => {
                setConfigs(response.data)
            })
            .catch(async (error) => {
                console.log(error);
            });
    }
    const getLogsByDevice = (value) => {
        axios.post(`${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/general/${BackendCurrent.context.profile.userId}/getLogsFile`, {
            DeviceID: value
        }, {headers: {'Authorization': BackendCurrent.context.authToken}})
            .then(async (response) => {
                setLogs(response.data)
            })
            .catch(async (error) => {
                console.log(error);
            });
    }
    const getTestsByDevice = (value) => {
        axios.get(`${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/admins/${BackendCurrent.context.profile.userId}/getTestsByDevice/${value}`, {headers: {'Authorization': BackendCurrent.context.authToken}})
            .then(async (response) => {
                // console.log('response '+ (new Date()));
                const devices = data;
                response.data.forEach(test => {

                    test.testLen = 0;
                    test.AlgoPH = null;
                    if (test.AlgoResult.length !== 0) {
                        var _algophtype = typeof (test.AlgoResult.PH);
                        if (test.AlgoResult.PH !== null && test.AlgoResult.PH !== undefined && _algophtype === "number") {
                            test.AlgoPH = test.AlgoResult.PH.toFixed(1);
                        }
                    }
                    if (test['Finished_scan'].DateUTC) {
                        test.testLen = (Math.abs(new Date(test.Start_scan.DateLocal) - new Date(test.Finished_scan.DateUTC)) / 1000 / 60).toFixed(2);
                        test.Finished_scan.DateUTC = new Date(test.Finished_scan.DateUTC);

                    } else {
                        test.Finished_scan.DateUTC = '';
                    }
                    const positiveDoctorDiagnosisArray = test.Diagnosis.filter(x => x.Val);
                    const positiveDoctorDiagnosisNames = [];
                    positiveDoctorDiagnosisArray.forEach(x => {
                        positiveDoctorDiagnosisNames.push(x.Name);
                    });
                    test.positiveDoctorDiagnosisNames = positiveDoctorDiagnosisNames;


                    const positiveLabResultArray = test.LabResult.filter(x => x.Val);
                    const positiveLabResultNames = [];
                    positiveLabResultArray.forEach(x => {
                        positiveLabResultNames.push(x.Name);
                    });
                    test.positiveLabResultNames = positiveLabResultNames;

                    const positiveAlgoResultArray = test.AlgoResult.Diagnosis ? test.AlgoResult.Diagnosis.filter(x => x.Val) : [];
                    const positiveAlgoResultNames = [];
                    positiveAlgoResultArray.forEach(x => {
                        positiveAlgoResultNames.push(x.Name);
                    });
                    test.positiveAlgoResultNames = positiveAlgoResultNames;

                });
                const sortedTests = response.data.sort((a, b) => {
                    return new Date(a.Start_scan.DateLocal) -
                        new Date(b.Start_scan.DateLocal)
                });
                let pc = 1;
                let pcList = [];
                for (let i = 0; i < sortedTests.length; i += pageCount) {
                    pcList.push(pc)
                    pc += 1;
                }
                SetPagesCount(pcList)

                devices.filter(x => x.DeviceID === value)[0].tests = sortedTests;
                devices.filter(x => x.DeviceID === value)[0].filteredTests = sortedTests;

                setData(devices)
                setFromDate((new Date()))
                setToDate((new Date()))

            })
            .catch(async (error) => {
                console.log(error);
            });
    }

    const imgDownloadFormatter = (cell, row) => {
        return (<div className='download-button' onClick={this.getTestImages.bind(this, row.TestID)}>Download</div>)
    }

    const lenDateFormatter = (cell) => {
        return (<div>{Math.round(cell)} min</div>)
    }
    const dateFormatter = (cellDate) => {
        if (cellDate) {
            const cell = new Date(cellDate);
            const d = cell ? cell.getDate() + "/" + (cell.getMonth() + 1) + "/" + String(cell.getFullYear()).replace('20', '') + "     " + cell.getHours() + ":" + (String(cell.getMinutes()).length >= 2 ? cell.getMinutes() : '0' + cell.getMinutes()) : "-";

            return (d)
        } else {
            return '';
        }

    }
    const deviceClicable = (cell) => {
        return (<div className='download-button'>{cell}</div>)
    }
    const diagFormatter = (cell) => {
        return (
            <div className='diag-container'>
                {
                    cell.map(x =>
                        <div>{x}</div>
                    )
                }
            </div>
        );
    }

    const backToDevices = () => {
        SetSelectedDevice(null)
        SetConfigsView(false)
        SetLogsView(false)
        SetCurrentPage(1)
        setLogs([])
        setConfigs([])
        SetTestsView(false)
    }
    const backToTests = () => {
        SetImagesView(null)
        setAlgoImages([])
    }
    const ShowTests = (TestId) => {
        SetTestsView(TestId)
        getTestsByDevice(TestId)
    }
    const ShowConfigs = (DeviceID) => {
        SetConfigsView(DeviceID)
        getConfigsByDevice(DeviceID)
    }
    const ShowLogs = (DeviceID) => {
        SetLogsView(DeviceID)
        getLogsByDevice(DeviceID)
    }
    const FormatTime = (time) => {
        return moment(time).format('DD/MM/YYYY HH:MM:SS')
    }

    const GetTestImages = (test) => {
        SetImagesView(test.TestID);
        axios.get(`${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/admins/${BackendCurrent.context.profile.userId}/getTestImages/${test.TestID}`, {headers: {'Authorization': BackendCurrent.context.authToken}})
            .then(async (response) => {
                if(Config.getImagesFromZip){
                    openZipFiles(test, response.data.inputFiles, response.data.outputFiles)
                }else{
                    getImagesFromOutput(test,response.data.outputFiles)
                }

            })
            .catch(async (error) => {
                console.log(error);
            });
    }
    const getDiagnosisFullName = (name) => {
        switch (name) {
            case "BV":
                return "Bacterial Vaginosis"
            case "CAS" :
            case "CAH" :
                return "Candida Albicans"
            case "CNA" :
                return "Candida Non-Albicans"
            case "AV"  :
                return "Vaginal Atrophy"
            case "TV"  :
                return "Trichomonas"
            case "DIV"  :
                return "DIV"
            case "CV"  :
                return "Cytolytic Vaginosis"
            default :
                return "Unknown"
        }

    }
    const getImageUrlByDiagnosis = (diagnosisImage) => {

        const content = new Uint8Array(diagnosisImage);
        let blob = URL.createObjectURL(
            new Blob([content.buffer], {type: 'image/png'} /* (1) */)
        );
        return blob
    }
    const getImagesFromOutput=(TEST_DATA,outputs)=>{
        console.log("GET_IMAGES_FROM_OUTPUT")

        let RESPONSE = [];

        var _algoImages = [];
        var _inputImages = [];

        outputs.forEach(output => {
            if(Object.keys(output)[0].indexOf("orig")>-1){
                _inputImages.push({
                    fileName : Object.keys(output)[0],
                    url:output[Object.keys(output)[0]]
                })
            }else{
                _algoImages.push({
                    fileName : Object.keys(output)[0],
                    url:output[Object.keys(output)[0]]
                })
            }

        })
        var _outputImages = [];
        _algoImages.forEach(image=>{
            let outputImageName = image.fileName; //BV_O38.jpg.jpg
            let outputImageUrl = image.url

            let inputFile = _inputImages.filter(x=>x.fileName.indexOf(outputImageName.split(".")[0]) > -1)[0];
            let inputFileName = inputFile && inputFile.fileName;
            let inputFileUrl = inputFile && inputFile.url;

            let diagnosisName = outputImageName.replace("KOH","").split("_")[0]; //BV

            if(TEST_DATA.AlgoResult.Diagnosis.find(x => x.Name === getDiagnosisFullName(diagnosisName)).Val === true){

                _outputImages.push({
                    outputImageName: outputImageName,
                    diagnosisName: diagnosisName,
                    diagnosisFullName: getDiagnosisFullName(diagnosisName) === 'Trichomonas'?"Trichomonas Vaginalis":getDiagnosisFullName(diagnosisName),
                    inputImageName: inputFileName,
                    inputImageUrl: inputFileUrl,
                    outputImageUrl: outputImageUrl
                })
                setTimeout(() => {
                    setAlgoImages(
                        _outputImages
                    )
                }, 1000)
            }
        })
    }

    const openZipFiles = (TEST_DATA, zips, outputs) => {

        let RESPONSE = [];

        var _algoImages = [];
        var _outputImages = [];

        outputs.forEach(output => {
            _algoImages.push({
                output: output,
                input: {
                    name: null,
                    data: []
                }
            })
        })


        zips.forEach(zipUrl => {

            // let k = Object.keys(zipUrl)[0];
            // if(k === 'sal'){
            let key = Object.keys(zipUrl);
            let url = zipUrl[key];
            let req = http.get(url, (res) => {
                if (res.statusCode !== 200) {
                    console.log(res.statusCode);
                    return;
                }
                var data = [], dataLen = 0;

                // don't set the encoding, it will break everything !
                // or, if you must, set it to null. In that case the chunk will be a string.

                res.on("data", (chunk) => {
                    data.push(chunk);
                    dataLen += chunk.length;
                });

                res.on("end", () => {
                    var buf = Buffer.concat(data);
                    // here we go !


                    JSZip.loadAsync(buf).then((zip) => {

                        //BV_038.jpg.jpg
                        _algoImages.forEach(image => {
                            let outputImageName = Object.keys(image.output)[0]; //BV_O38.jpg.jpg
                            let outputImageUrl = image.output[Object.keys(image.output)[0]];
                            let diagnosisName = Object.keys(image.output)[0].replace("KOH", "").split("_")[0]; //BV
                            let inputImageName = Object.keys(image.output)[0].replace("KOH", "").split("_")[1].slice(0, -4); //038.jpg
                            let inputImageData = null;
                            Object.keys(zip.files).forEach(file => {
                                if (zip.files[file].name === inputImageName) {
                                    zip.file(file).async("uint8array").then((u8) => {
                                        if (TEST_DATA.AlgoResult.Diagnosis.find(x => x.Name === getDiagnosisFullName(diagnosisName)).Val === true) {

                                            _outputImages.push({
                                                outputImageName: outputImageName,
                                                diagnosisName: diagnosisName,
                                                diagnosisFullName: getDiagnosisFullName(diagnosisName) === 'Trichomonas'?"Trichomonas Vaginalis":getDiagnosisFullName(diagnosisName),
                                                inputImageName: inputImageName,
                                                inputImageUrl: getImageUrlByDiagnosis(u8),
                                                outputImageUrl: image.output[Object.keys(image.output)[0]]
                                            })
                                            setTimeout(() => {
                                                setAlgoImages(
                                                    _outputImages
                                                )
                                            }, 1000)

                                        }
                                    })
                                }

                            })
                        })

                    }).then((text) => {
                        console.log(text);
                    });


                });
            });

            req.on("error", (err) => {
                // handle error
            });
        })
    }

    const [algoImages, setAlgoImages] = useState([])
    const [imagesView, SetImagesView] = useState(null)


    const pageCount = 8;
    const [testsView, SetTestsView] = useState(null)
    const [pagesCount, SetPagesCount] = useState([1])
    const [currentPage, SetCurrentPage] = useState(1);

    const [logsView, SetLogsView] = useState(null)
    const [configsView,SetConfigsView] = useState(null)
    const [File,SetFile] = useState(null)

    const PickFile=(file)=>{
        SetFile(null)
        SetFile(file.target.files[0])
    }
    const validateFileName=(fileName)=>{
        return fileName.match(/([a-zA-Z0-9])+(_)+([0-9])+(.txt|.ini|.yaml)$/)
    }

    const UploadFile=()=>{
        if(!validateFileName(File.name)){
            alert("Wrong File Name Format")
            return
        }
        let formData = new FormData();
        formData.append("iniFile", File);

        axios.post(`${Config.globalUrl}:${Config.globalPort}/api/v1/webapp/general/${BackendCurrent.context.profile.userId}/uploadIniFile/`+selectedDevice,formData, {
            headers: {
                'Authorization': BackendCurrent.context.authToken,
                'Content-Type': File.type
            }
        }).then(async (response) => {
            getConfigsByDevice(selectedDevice)
        }).catch(async (error) => {
            console.log(error);
        });
    }

    return (
        <div className={"clinic-details-height-responsive pt-2 mt-4"}>

            <div className={"  ml-10 sm:ml-6.25rem mr-10 sm:mr-6.25rem mb-3.75rem shadow-gyni rounded-2xl"}>
                <div className={"lg:flex"}>

                    <div className={"pl-20 pr-20 pt-5 pb-5 w-full"}>
                        {
                            testsView
                                ?
                                imagesView
                                    ?
                                    <div>
                                        <div
                                            className={"cursor-pointer border w-20 flex justify-content-center rounded-xl shadow-xl transform hover:scale-125 mb-2"}
                                            onClick={() => backToTests()}>back
                                        </div>
                                        <div className={"flex space-x-10 place-items-center justify-content-center "}>
                                            {
                                                algoImages && algoImages.map(image => <div
                                                        className={"border space-y-12 p-8 shadow-xl rounded-xl"}>
                                                        <GSmallLabel bold>{image.diagnosisFullName}</GSmallLabel>
                                                        {
                                                            image.inputImageUrl
                                                            ?
                                                                <img
                                                                    className={" sm:h-20.938rem lg:h-11rem transform hover:scale-150 hover:shadow-xl"}
                                                                    src={image.inputImageUrl} alt={"diagnosis image"}/>
                                                            :
                                                                <div className={" sm:h-20.938rem lg:h-11rem transform "}>
                                                                    No image
                                                                </div>
                                                        }
                                                        {
                                                            image.outputImageUrl
                                                            ?
                                                                <img
                                                                    className={"sm:h-20.938rem lg:h-11rem transform hover:scale-150 hover:shadow-xl"}
                                                                    src={image.outputImageUrl} alt={"diagnosis image"}/>
                                                            :
                                                                <div className={"sm:h-20.938rem lg:h-11rem transform "}>
                                                                    No image
                                                                </div>
                                                        }

                                                    </div>
                                                )
                                            }

                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <div>
                                            <div
                                                className={"cursor-pointer border w-20 flex justify-content-center rounded-xl shadow-xl transform hover:scale-125 mb-2"}
                                                onClick={() => backToDevices()}>back
                                            </div>
                                            <div className={"flex  space-x-2 w-full justify-content-end"}>
                                                <div className={"flex space-x-2"}>
                                                    <GSmallLabel>Search By ID</GSmallLabel>
                                                    <input className={" border flex justify-content-center rounded-xl shadow-xl transform mb-2 pl-2"} onKeyUp={(e)=>SF.ApplySearch("tests-table",e.target.value)}/>
                                                </div>
                                                <div
                                                    className={"cursor-pointer border w-20 flex justify-content-center rounded-xl shadow-xl transform hover:scale-125 mb-2"}
                                                    onClick={() => PDF.ExportToPDF("Tests", '#tests-table', 'tests')}
                                                >PDF
                                                </div>
                                                <div
                                                    className={"cursor-pointer border w-20 flex justify-content-center rounded-xl shadow-xl transform hover:scale-125 mb-2"}
                                                    onClick={() => CSV.ExportToCSV("Tests", 'tests-table', 'tests' )}
                                                >CSV
                                                </div>
                                            </div>
                                            <table id={"tests-table"} className={"table-auto text-sm w-full border"}>
                                                <thead className={"border-bottom"}>
                                                <tr>
                                                    <th className={"bg-gray-200 border-white border-2 pl-1"}>ID</th>
                                                    <th className={"bg-gray-200 border-white border-2 pl-1"}>Clinic ID
                                                    </th>
                                                    <th className={"bg-gray-200 border-white border-2 pl-1"}>User ID
                                                    </th>
                                                    <th className={""}>Patient YOB</th>
                                                    <td className={""}>Complaints</td>
                                                    <th className={""}>Start Scan Date</th>
                                                    <th className={""}>Finished Scan</th>
                                                    <th className={""}>Algorithm Result</th>
                                                    <th className={""}>Algorithm PH</th>
                                                    <th className={""}>Status</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    data.filter(x => x.DeviceID === testsView).map((device, ri) =>
                                                        // device.tests.slice(currentPage * pageCount, currentPage * pageCount + pageCount).map((test, ti) =>
                                                        device.tests.map((test, ti) =>
                                                            <>
                                                                <tr key={"osk-" + ti}
                                                                    className={(ti % 2 !== 0 ? " bg-gray-100  " : " ") + " hover:bg-gray-300 cursor-pointer"}>
                                                                    <td className={"border-white border-2 pl-1"}>
                                                                        {
                                                                            test.TestID
                                                                        }
                                                                    </td>
                                                                    <td className={"border-white border-2 pl-1"}>
                                                                        {
                                                                            test.ClinicID
                                                                        }
                                                                    </td>
                                                                    <td className={"border-white border-2 pl-1"}>
                                                                        {
                                                                            test.UserID
                                                                        }
                                                                    </td>
                                                                    <td className={"border-white border-2 pl-1"}>
                                                                        {
                                                                            test.PatientYearOfBirth
                                                                        }
                                                                    </td>
                                                                    <td className={"border-white border-2 pl-1"}>
                                                                        {
                                                                            test.Complaints.filter(x => x.Val === true).map((comp, i) =>
                                                                                <div key={"comp" + i}>{comp.Name}</div>
                                                                            )
                                                                        }
                                                                    </td>
                                                                    <td className={"border-white border-2 pl-1"}>
                                                                        {
                                                                            test.Start_scan && FormatTime(test.Start_scan.DateLocal)
                                                                        }
                                                                    </td>
                                                                    <td className={"border-white border-2 pl-1"}>
                                                                        {
                                                                            test.Finished_scan && FormatTime(test.Finished_scan.DateUTC.toLocaleString())
                                                                        }
                                                                    </td>
                                                                    <td className={"border-white border-2 pl-1"}>
                                                                        {
                                                                            test.AlgoResult && test.AlgoResult.length > 0 && test.AlgoResult.Diagnosis.filter(x => x.Val === true).map((diag, i) =>
                                                                                <div key={"diag" + i}>{diag.Name}</div>
                                                                            )
                                                                        }
                                                                    </td>
                                                                    <td className={"border-white border-2 pl-1"}>
                                                                        {
                                                                            test.AlgoPH
                                                                        }
                                                                    </td>
                                                                    <td className={"border-white border-2 pl-1"}>
                                                                        {
                                                                            test.Resolution && test.Resolution.Status
                                                                        }
                                                                    </td>
                                                                    <td onClick={() => GetTestImages(test)}
                                                                        className={"cursor-pointer border-white border-2 pl-1"}>
                                                                        View Images
                                                                    </td>

                                                                </tr>
                                                            </>
                                                        )
                                                    )

                                                }

                                                </tbody>
                                            </table>
                                        </div>
                                        <div className={" flex space-x-2 text-xs justify-content-center mt-2"}>
                                            {/*{*/}
                                            {/*    pagesCount.length > 0*/}
                                            {/*        ?*/}
                                            {/*        <div*/}
                                            {/*            className={" cursor-pointer " + (currentPage === 1 ? " text-blue-700 " : " text-gray-300 ") + " hover:text-blue-700 "}*/}
                                            {/*            onClick={() => SetCurrentPage(1)}>{1}</div>*/}
                                            {/*        :*/}
                                            {/*        null*/}
                                            {/*}*/}
                                            {/*{*/}
                                            {/*    pagesCount.length > pageCount*/}
                                            {/*        ?*/}
                                            {/*        <div>...</div>*/}
                                            {/*        :*/}
                                            {/*        null*/}
                                            {/*}*/}
                                            {/*{*/}
                                            {/*    pagesCount.slice(0, pagesCount.length - 1).slice(currentPage - 5 > 0 ? currentPage - 4 : 1, currentPage - 5 > 0 ? currentPage + 5 : currentPage + 10).map(p =>*/}
                                            {/*        <div*/}
                                            {/*            className={" cursor-pointer " + (currentPage === p ? " text-blue-700 " : " text-gray-300 ") + " hover:text-blue-700 "}*/}
                                            {/*            onClick={() => SetCurrentPage(p)}>{p}</div>)*/}
                                            {/*}*/}
                                            {/*{*/}
                                            {/*    pagesCount.length > pageCount*/}
                                            {/*        ?*/}
                                            {/*        <div>...</div>*/}
                                            {/*        :*/}
                                            {/*        null*/}
                                            {/*}*/}
                                            {/*{*/}
                                            {/*    pagesCount.length > 0*/}
                                            {/*        ?*/}
                                            {/*        <div*/}
                                            {/*            className={" cursor-pointer " + (currentPage === pagesCount.length ? " text-blue-700 " : " text-gray-300 ") + " hover:text-blue-700 "}*/}
                                            {/*            onClick={() => SetCurrentPage(pagesCount[pagesCount.length - 1])}>{pagesCount[pagesCount.length - 1]}</div>*/}
                                            {/*        :*/}
                                            {/*        null*/}
                                            {/*}*/}

                                        </div>
                                    </div>
                                :
                                logsView
                                    ?
                                    <div>
                                        <div
                                            className={"cursor-pointer border w-20 flex justify-content-center rounded-xl shadow-xl transform hover:scale-125 mb-2"}
                                            onClick={() => backToDevices()}>back
                                        </div>
                                        <div className={"flex  space-x-2 w-full justify-content-end"}>
                                            <div className={"flex space-x-2"}>
                                                <GSmallLabel>Search By ID</GSmallLabel>
                                                <input className={" border flex justify-content-center rounded-xl shadow-xl transform mb-2 pl-2"} onKeyUp={(e)=>SF.ApplySearch("logs-table",e.target.value)}/>
                                            </div>
                                        </div>
                                        <table id={"logs-table"} className={"table-auto text-sm w-full border"}>
                                            <thead className={"border-bottom"}>
                                            <tr>
                                                <th className={"bg-gray-200 border-white border-2 pl-1"}>File</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                logs.map((log, ri) =>
                                                    <>
                                                        <tr key={"osk-" + ri}
                                                            className={(ri % 2 !== 0 ? " bg-gray-100  " : " ") + " hover:bg-gray-300 cursor-pointer"}>
                                                            <td className={"border-white border-2 pl-1 hover:bg-gyniCyan"}>
                                                                <a className={"text-black"} href={log[Object.keys(log)]} >
                                                                    {
                                                                        Object.keys(log)
                                                                    }
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    </>
                                                )

                                            }

                                            </tbody>
                                        </table>
                                    </div>
                                    :
                                    configsView
                                    ?
                                        <div>
                                            <div
                                                className={"cursor-pointer border w-20 flex justify-content-center rounded-xl shadow-xl transform hover:scale-125 mb-2"}
                                                onClick={() => backToDevices()}>back
                                            </div>
                                            <div className={"flex  space-x-2 w-full justify-content-end"}>
                                                <div className={"flex space-x-2"}>
                                                    <GSmallLabel>Search By ID</GSmallLabel>
                                                    <input className={" border flex justify-content-center rounded-xl shadow-xl transform mb-2 pl-2"} onKeyUp={(e)=>SF.ApplySearch("logs-table",e.target.value)}/>
                                                </div>
                                            </div>
                                            <table id={"configs-table"} className={"table-auto text-sm w-full border"}>
                                                <thead className={"border-bottom"}>
                                                <tr>
                                                    <th className={"bg-gray-200 border-white border-2 pl-1"}>Config File</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className={" hover:bg-gray-300 cursor-pointer"}>
                                                        <td className={"border-white border-2 pl-1 hover:bg-gyniCyan"}>
                                                                {
                                                                    configs
                                                                }
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div className={"flex"}>
                                                <div className={"w-1/2"}>
                                                    <input accept={".yaml"} className={"cursor-pointer flex "} onChange={(evt)=>PickFile(evt)} type="file"/>
                                                </div>
                                                <div className={"w-1/2 flex justify-content-end"}>
                                                    <div className={"cursor-pointer border w-20 rounded-xl flex shadow-xl  justify-content-center"} onClick={()=>UploadFile()}>Add</div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                        <div className={"flex  space-x-2 w-full justify-content-end"}>
                                            <div className={"flex space-x-2"}>
                                                <GSmallLabel>Search By ID</GSmallLabel>
                                                <input className={" border flex justify-content-center rounded-xl shadow-xl transform mb-2 pl-2"} onKeyUp={(e)=>SF.ApplySearch("devices-table",e.target.value)}/>
                                            </div>
                                            <div
                                                className={"cursor-pointer border w-20 flex justify-content-center rounded-xl shadow-xl transform hover:scale-125 mb-2"}
                                                onClick={() => PDF.ExportToPDF("Devices", '#devices-table', 'devices')}
                                            >PDF
                                            </div>
                                            <div
                                                className={"cursor-pointer border w-20 flex justify-content-center rounded-xl shadow-xl transform hover:scale-125 mb-2"}
                                                onClick={() => CSV.ExportToCSV("Devices", 'devices-table', 'devices' )}
                                            >CSV
                                            </div>
                                        </div>
                                        <table id={"devices-table"} className={"table-auto text-sm w-full border"}>
                                            <thead className={"border-bottom"}>
                                            <tr>
                                                <th className={"bg-gray-200 border-white border-2 pl-1"}>ID</th>
                                                <th className={"bg-gray-200 border-white border-2 pl-1"}>SN</th>
                                                <th className={"bg-gray-200 border-white border-2 pl-1"}>FW Version</th>
                                                <th className={"bg-gray-200 border-white border-2 pl-1"}>Internal IP</th>
                                                <th className={"bg-gray-200 border-white border-2 pl-1"}>External IP</th>
                                                <th className={"bg-gray-200 border-white border-2 pl-1"}>Config Version</th>
                                                <th className={"bg-gray-200 border-white border-2 pl-1"}>Clinic ID</th>
                                                <th className={"bg-gray-200 border-white border-2 pl-1"}>Status</th>
                                                <th className={"bg-gray-200 border-white border-2 pl-1"}>Current Test</th>
                                                <th className={"bg-gray-200 border-white border-2 pl-1"}>Tests Count</th>
                                                <th className={"bg-gray-200 border-white border-2 pl-1"}>Last Test</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                data.map((device, ri) =>
                                                    <>
                                                        <tr key={"osk-" + ri}
                                                            className={(ri % 2 !== 0 ? " bg-gray-100  " : " ") + " hover:bg-gray-300 cursor-pointer"}>
                                                            <td className={"border-white border-2 pl-1"}>
                                                                {
                                                                    device.DeviceID
                                                                }
                                                            </td>
                                                            <td className={"border-white border-2 pl-1"}>
                                                                {
                                                                    device.SerialNumber
                                                                }
                                                            </td>
                                                            <td className={"border-white border-2 pl-1"}>
                                                                {
                                                                    device.FirmwareVersion ? device.FirmwareVersion : "Unknown"
                                                                }
                                                            </td>
                                                            <td className={"border-white border-2 pl-1"}>
                                                                {
                                                                    device.DeviceIP ? device.DeviceIP : "Unknown"
                                                                }
                                                            </td>
                                                            <td className={"border-white border-2 pl-1"}>
                                                                {
                                                                    device.deviceExternalIP ? device.deviceExternalIP : "Unknown"
                                                                }
                                                            </td>
                                                            <td className={"border-white border-2 pl-1"}>
                                                                {
                                                                    device.IniFileVersion ? device.IniFileVersion : "Unknown"
                                                                }
                                                            </td>
                                                            <td className={"border-white border-2 pl-1"}>
                                                                {
                                                                    device.ClinicID
                                                                }
                                                            </td>
                                                            <td className={"border-white border-2 pl-1"}>
                                                                {
                                                                    device.IsEnabled === true ? " Enabled " : " Disabled "
                                                                }
                                                            </td>
                                                            <td className={"border-white border-2 pl-1"}>
                                                                {
                                                                    device.CurrentTestID
                                                                }
                                                            </td>
                                                            <td className={"border-white border-2 pl-1"}>
                                                                {
                                                                    device.testCount
                                                                }
                                                            </td>
                                                            <td className={"border-white border-2 pl-1"}>
                                                                {
                                                                    device.lastTestTime
                                                                }
                                                            </td>
                                                            <td onClick={() => ShowTests(device.DeviceID)}>
                                                                View Tests
                                                            </td>
                                                            <td onClick={() => ShowLogs(device.DeviceID)}>
                                                                Logs
                                                            </td>
                                                            <td onClick={() => ShowConfigs(device.DeviceID)}>
                                                                Config Files
                                                            </td>
                                                        </tr>
                                                    </>
                                                )

                                            }

                                            </tbody>
                                        </table>
                                    </div>

                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OverallScannerViewPage;

