import React, {useContext, useState} from 'react';
import GSmallLabel from "./GSmallLabel";
import GToggleButton from "./GToggleButton";
import {useTranslation} from "react-i18next";

const GDiagnosisFullScreen = (props) => {
    const { t, i18n } = useTranslation()
    const [viewOverlay,setViewOverlay] = useState(false)
    return (
        <div className={props.className+" bg-#363636 rounded-xl "}>

            <div className={"flex h-3.25rem pl-1.25rem pr-1.25rem space-x-0.875rem"}>
                {/*<GSmallLabel className={"text-white mt-auto mb-auto"}>{t("general.gyni-diagnosis")}{props.index}</GSmallLabel>*/}
                <GSmallLabel className={"text-white mt-auto mb-auto"}>{t("general.gyni-diagnosis")}</GSmallLabel>
                <GSmallLabel className={"text-white  mt-auto mb-auto"} bold>{props.diagnosis.diagnosis.Name}</GSmallLabel>
            </div>

            <div className={""}>
                {
                    viewOverlay
                        ?
                        <div onClick={props.onClick} className={""}>
                            {
                                <img className={"sm:w-full sm:h-full"} src={props.diagnosis.image.outputImageUrl} alt={"diagnosis image"}/>
                            }
                        </div>
                        :
                        <div onClick={props.onClick} className={""}>
                            {
                                props.diagnosis.image.inputImageUrl
                                ?
                                    <img className={"sm:w-full sm:h-full"} src={props.diagnosis.image.inputImageUrl} alt={"diagnosis image"}/>
                                :
                                    <div className={"flex h-3.25rem pl-1.25rem pr-1.25rem space-x-0.875rem"}>
                                        <div className={"text-white mt-auto mb-auto m-auto"}>No Image</div>
                                    </div>
                            }
                        </div>
                }
            </div>
            <div className={"h-3.25rem flex pr-1rem  pl-1rem"}>
                <GToggleButton textColor={"mt-auto mb-auto text-white"} onClick={()=>setViewOverlay(!viewOverlay)} text={t("test.diagnosis-overlay")}/>
            </div>
        </div>
    )
};
export default GDiagnosisFullScreen;
