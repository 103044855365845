import React, {Component} from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Config from "./Config";

class ReCaptcha extends Component {
    render() {
        let {action, setRef, setErr} = this.props
        return <ReCAPTCHA

            ref={setRef}
            sitekey={Config.isDev ? Config.DevReCaptcha : Config.isStaging ? Config.StagingReCaptcha : Config.ReCaptcha}
            size={'invisible'}
            explicit={false}
            onChange={async reCaptchaToken => {
                if (!!reCaptchaToken) {
                    await action(reCaptchaToken)
                } else {
                    setErr('Blocked By ReCAPTCHA')
                }
            }}
            onVerify={async reCaptchaToken => {
                if (!!reCaptchaToken) {
                    await action(reCaptchaToken)
                } else {
                    setErr('Blocked By ReCAPTCHA')
                }
            }}
            onExpired={() => {
                setErr(
                    'ReCAPTCHA Expired, please refresh the page'
                )
            }}
            onErrored={(err) => {
                setErr('ReCAPTCHA Error');
                console.error(err)
            }}
        />;
    }
}

export default ReCaptcha;
