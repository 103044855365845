import React, {useContext, useEffect, useState} from 'react'
import GBigLabel from "./GBigLabel";
import myVideo from "../assets/gynd-movie.mp4";
import GSmallLabel from "./GSmallLabel";
import GButton from "./GButton";
import GTransparentButton from "./GTransparentButton";
import GModal from "./GModal";
import {GlobalContext} from "../machines/GlobalStateMachine";
import {useActor} from "@xstate/react";
import {useTranslation} from "react-i18next";
import GCheckBox from "./GCheckBox";

const TutorialVideo=()=>{
    const { t, i18n } = useTranslation()
    const GContext = useContext(GlobalContext)
    const [GlobalCurrentState, UpdateGlobal] = useActor(GContext);
    const [lang,setLang] = useState(localStorage.getItem("language"))

    const SetLanguage=(lang)=>{

        setLang(lang)

    }
    const SwitchLanguage=()=>{
        i18n.changeLanguage(lang)
        localStorage.setItem("language",lang)
    }
    useEffect(()=>{
        SetLanguage(localStorage.getItem("language"))
    },[])
    const  languageSelector=()=>{
        switch(lang){
            case "en":
                return <div className={"pl-28 pr-28"}>
                    <GCheckBox className={"h-6"} onClick={()=>SetLanguage("en")} checked={true} text={"English"}/>
                    <GCheckBox className={"h-6"} onClick={()=>SetLanguage("ru")} checked={false} text={"Russian"}/>
                    <GCheckBox className={"h-6"} onClick={()=>SetLanguage("de")} checked={false} text={"German"}/>
                    <GCheckBox className={"h-6"} onClick={()=>SetLanguage("it")} checked={false} text={"Italian"}/>
                    <GCheckBox className={"h-6"} onClick={()=>SetLanguage("iw")} checked={false} text={"Hebrew"}/>
                    <GCheckBox className={"h-6"} onClick={()=>SetLanguage("fr")} checked={false} text={"French"}/>
                </div>
            case "ru":
                return <div className={"pl-28 pr-28"}>
                    <GCheckBox className={"h-6"} onClick={()=>SetLanguage("en")} checked={false} text={"English"}/>
                    <GCheckBox className={"h-6"} onClick={()=>SetLanguage("ru")} checked={true} text={"Russian"}/>
                    <GCheckBox className={"h-6"} onClick={()=>SetLanguage("de")} checked={false} text={"German"}/>
                    <GCheckBox className={"h-6"} onClick={()=>SetLanguage("it")} checked={false} text={"Italian"}/>
                    <GCheckBox className={"h-6"} onClick={()=>SetLanguage("iw")} checked={false} text={"Hebrew"}/>
                    <GCheckBox className={"h-6"} onClick={()=>SetLanguage("fr")} checked={false} text={"French"}/>
                </div>
            case "de":
                return <div className={"pl-28 pr-28"}>
                    <GCheckBox className={"h-6"} onClick={()=>SetLanguage("en")} checked={false} text={"English"}/>
                    <GCheckBox className={"h-6"} onClick={()=>SetLanguage("ru")} checked={false} text={"Russian"}/>
                    <GCheckBox className={"h-6"} onClick={()=>SetLanguage("de")} checked={true} text={"German"}/>
                    <GCheckBox className={"h-6"} onClick={()=>SetLanguage("it")} checked={false} text={"Italian"}/>
                    <GCheckBox className={"h-6"} onClick={()=>SetLanguage("iw")} checked={false} text={"Hebrew"}/>
                    <GCheckBox className={"h-6"} onClick={()=>SetLanguage("fr")} checked={false} text={"French"}/>
                </div>
            case "it":
                return <div className={"pl-28 pr-28"}>
                    <GCheckBox className={"h-6"} onClick={()=>SetLanguage("en")} checked={false} text={"English"}/>
                    <GCheckBox className={"h-6"} onClick={()=>SetLanguage("ru")} checked={false} text={"Russian"}/>
                    <GCheckBox className={"h-6"} onClick={()=>SetLanguage("de")} checked={false} text={"German"}/>
                    <GCheckBox className={"h-6"} onClick={()=>SetLanguage("it")} checked={true} text={"Italian"}/>
                    <GCheckBox className={"h-6"} onClick={()=>SetLanguage("iw")} checked={false} text={"Hebrew"}/>
                    <GCheckBox className={"h-6"} onClick={()=>SetLanguage("fr")} checked={false} text={"French"}/>
                </div>
            case "iw":
                return <div className={"pl-28 pr-28"}>
                    <GCheckBox className={"h-6"} onClick={()=>SetLanguage("en")} checked={false} text={"English"}/>
                    <GCheckBox className={"h-6"} onClick={()=>SetLanguage("ru")} checked={false} text={"Russian"}/>
                    <GCheckBox className={"h-6"} onClick={()=>SetLanguage("de")} checked={false} text={"German"}/>
                    <GCheckBox className={"h-6"} onClick={()=>SetLanguage("it")} checked={false} text={"Italian"}/>
                    <GCheckBox className={"h-6"} onClick={()=>SetLanguage("iw")} checked={true} text={"Hebrew"}/>
                    <GCheckBox className={"h-6"} onClick={()=>SetLanguage("fr")} checked={false} text={"French"}/>
                </div>
            case "fr":
                return <div className={"pl-28 pr-28"}>
                    <GCheckBox className={"h-6"} onClick={()=>SetLanguage("en")} checked={false} text={"English"}/>
                    <GCheckBox className={"h-6"} onClick={()=>SetLanguage("ru")} checked={false} text={"Russian"}/>
                    <GCheckBox className={"h-6"} onClick={()=>SetLanguage("de")} checked={false} text={"German"}/>
                    <GCheckBox className={"h-6"} onClick={()=>SetLanguage("it")} checked={false} text={"Italian"}/>
                    <GCheckBox className={"h-6"} onClick={()=>SetLanguage("iw")} checked={false} text={"Hebrew"}/>
                    <GCheckBox className={"h-6"} onClick={()=>SetLanguage("fr")} checked={true} text={"French"}/>
                </div>
            default:
                return <div className={"pl-28 pr-28"}>
                    <GCheckBox className={"h-6"} onClick={()=>SetLanguage("en")} checked={true} text={"English"}/>
                    <GCheckBox className={"h-6"} onClick={()=>SetLanguage("ru")} checked={false} text={"Russian"}/>
                    <GCheckBox className={"h-6"} onClick={()=>SetLanguage("de")} checked={false} text={"German"}/>
                    <GCheckBox className={"h-6"} onClick={()=>SetLanguage("it")} checked={false} text={"Italian"}/>
                    <GCheckBox className={"h-6"} onClick={()=>SetLanguage("iw")} checked={false} text={"Hebrew"}/>
                    <GCheckBox className={"h-6"} onClick={()=>SetLanguage("fr")} checked={false} text={"French"}/>
                </div>
        }
    }

    return(
        <GModal  handleClose={()=>{ UpdateGlobal({type:"CLOSE_LANGUAGE_SELECTOR"})}}>
            <div className={" "}>
                <GBigLabel className={"sm:text-2rem mt-1.5rem text-center"}>
                    {t("language")}
                </GBigLabel>
                <GBigLabel className={"sm:text-1.25rem text-center"}>
                    {t("language.please-select-language")}
                </GBigLabel>
                <div className={"lg:mt-3rem  align-items-center pb-16"}>
                        {
                            languageSelector()
                        }
                </div>
                <GButton className={"ml-auto mr-auto w-7.5rem h-3.125rem"} onClick={()=>SwitchLanguage()} >
                    {t("general.save")}
                </GButton>
            </div>
        </GModal>
    )
}
export default TutorialVideo
