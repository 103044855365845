import React, {useContext, useState} from "react"
import GBigLabel from "./GBigLabel";
import GSmallLabel from "./GSmallLabel";
import GBaloonDiv from "./GBaloonDiv";
import GCarousel from "./GCarousel";
import GDiagnosis from "./GDiagnosis";
import GToggleButton from "./GToggleButton";
import GIconLabelButton from "./GIconLabelButton";
import mail_icon from "../assets/mail_icon_svg.svg";
import pdf_icon from "../assets/pdficon.svg"
import {useTranslation} from "react-i18next";
import axios from "axios";
import Config from "../services/Config";
import {BackendContext} from "../machines/BackendMachine";
import {useActor} from "@xstate/react";
import {GlobalContext} from "../machines/GlobalStateMachine";

const GTestSmallView =(props)=>{
    const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN",
        "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"
    ];
    const { t, i18n } = useTranslation()
    const [OverlayView,setOverlayView] = useState(false);
    const BEContext = useContext(BackendContext)
    let BackendCurrent, BESend;
    [BackendCurrent, BESend] = useActor(BEContext);
    const GContext = useContext(GlobalContext)
    const [GlobalCurrentState, UpdateGlobal] = useActor(GContext);

    const downloadPdf=()=>{
        alert("TODO");
    }
    const emailThisToMe=()=>{
        UpdateGlobal({type:"SET_EMAIL_POPUP",testSummaryNumber:props.TestId});
    }

    return(
        <div   className={""}>
            {
                props.smallHeader
                ?
                    <div onClick={props.onClick} className={" cursor-pointer w-full  text-center flex border-b-1px " + props.paddings}>
                        <div className={"w-1/2"}>
                            <div className={"flex space-x-2"}>
                                <GBigLabel className={"flex leading-1.44rem "}>{t("test.test-number-short")}</GBigLabel>
                                <GBigLabel className={"flex leading-1.44rem "}>{props.TestId}</GBigLabel>
                            </div>

                        </div>
                        <div className={"w-1/2 flex justify-content-end space-x-6"}>
                            <GIconLabelButton onClick={()=>downloadPdf()} className={"justify-end leading-1.44rem whitespace-nowrap h-full  flex text-0.875rem"} textColor={"text-#1396B4 "} iconImage={pdf_icon} text={t("test.download-pdf")}/>
                            <GIconLabelButton onClick={()=>emailThisToMe()} className={"justify-end leading-1.44rem whitespace-nowrap h-full  flex text-0.875rem"} textColor={"text-#1396B4 "} iconImage={mail_icon} text={t("test.email-this-to-me")}/>
                        </div>
                    </div>
                :
                    <div className={"w-full mb-1.813rem lg:mb-3.375rem text-center flex " + props.paddings}>
                        <div className={"w-1/2"}>
                            <div className={"flex space-x-2"}>
                                <GBigLabel className={"lg:flex hidden sm:text-1.375rem lg:text-2rem font-1.125rem"}>{t("test.test-number")}</GBigLabel>
                                <GBigLabel className={"lg:flex hidden sm:text-1.375rem lg:text-2rem font-1.125rem"}> {props.TestId}</GBigLabel>
                            </div>
                            <div className={"flex space-x-2"}>
                                <GBigLabel className={"lg:hidden flex sm:text-1.375rem lg:text-2rem font-1.125rem"}>{t("test.test-number-short")}</GBigLabel>
                                <GBigLabel className={"lg:hidden flex sm:text-1.375rem lg:text-2rem font-1.125rem"}>{props.TestId}</GBigLabel>
                            </div>
                        </div>
                        <div className={"w-1/2 flex justify-content-end space-x-6"}>
                            <GIconLabelButton onClick={()=>downloadPdf()} className={"justify-end whitespace-nowrap h-full lg:hidden flex text-0.875rem"} textColor={"text-#1396B4"} iconImage={pdf_icon} text={t("test.download-pdf")}/>
                            <GIconLabelButton onClick={()=>emailThisToMe()} className={"justify-end whitespace-nowrap h-full lg:hidden flex text-0.875rem"} textColor={"text-#1396B4"} iconImage={mail_icon} text={t("test.email-this-to-me")}/>
                        </div>
                    </div>
            }

            <div className={" lg:flex  " +( props.smallBody ? " " : " lg:mb-6.75rem ")  + props.paddings}>
                <div className={"w-full flex pb-1.563rem lg:pb-0 border-b  lg:border-r "}>
                    <div className={"w-1/2  mb-1.813rem max-h-2.375rem lg:mb-2.625rem"}>
                        <GSmallLabel className={"text-sm"}>{t("test.test-date-and-time")}</GSmallLabel>
                        <GSmallLabel className={"flex space-x-2"} bold >
                            <div>
                                {
                                    (new Date(props.TestDate)).getDate()
                                }
                                /
                                {
                                    monthNames[(new Date(props.TestDate)).getMonth()]
                                }
                                /
                                {
                                    (new Date(props.TestDate)).getFullYear()
                                }
                            </div>

                            <div>
                                {
                                    (new Date(props.TestDate)).getHours()
                                }
                                :
                                {
                                    (new Date(props.TestDate)).getMinutes()
                                }
                            </div>

                        </GSmallLabel>
                    </div>
                    <div className={" w-1/2   mb-1.813rem max-h-2.375rem lg:mb-2.625rem"}>
                        <GSmallLabel className={"text-sm"}>{t("test.patient-yob")}</GSmallLabel>
                        <GSmallLabel bold >
                            {props.PatientYearOfBirth} {t("general.age")} ({((new Date).getFullYear() - props.PatientYearOfBirth)})
                        </GSmallLabel>
                    </div>

                </div>

                <div className={" lg:w-full   max-w-85vw pb-1.563rem pt-1.563rem lg:pb-0 lg:pt-0  lg:pl-2 lg:flex lg:justify-content-center "}>
                    <GSmallLabel>{t("general.gyni-diagnosis")}</GSmallLabel>
                    {
                        props.Diagnosis.length === 0
                            ?
                            <GSmallLabel>{t("test.none")}</GSmallLabel>
                            :
                            <div className={" w-full"}>
                                {
                                    props.Diagnosis.map(
                                        (diagnosis,i)=>
                                            <GSmallLabel bold>{diagnosis.name}</GSmallLabel>
                                    )
                                }
                            </div>

                    }

                </div>
            </div>
        </div>
    )
}
export default GTestSmallView;
