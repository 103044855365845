import React, {useContext} from 'react'
import {BackendContext} from "../../machines/BackendMachine";
import {useActor, useMachine} from "@xstate/react";
import {LoginMachine,LoginContext} from "../../machines/LoginMachine";
import SignIn from "./SignIn";
import {GlobalMachine} from "../../machines/GlobalStateMachine";
import {TestMachine} from "../../machines/TestMachine";
import ForgotPasswordRequestPage from "./ForgotPasswordRequestPage";
import CreateUser from "./CreateUser";
import GResponsiveTest from "../GResponsiveTest";
import {version} from "../../../package.json"
import bgrb from "../../assets/bgrb.svg"
import bgrt from "../../assets/bgrt.svg"
import bglt from "../../assets/bglt.svg"
import {useTranslation} from "react-i18next";
import Config from "../../services/Config";
import TermsAndConditions from "../TermsAndConditions/TermsAndConditions";
import TermsAndConditionsSignIn from "../TermsAndConditions/TermsAndConditionsSignIn";

const Login=()=>{
    const { t, i18n } = useTranslation()
    const [current,,loginService] = useMachine(LoginMachine);

    const ViewCurrentRoute =()=>{
        switch (current.context.currentRoute){
            case "terms":
                return <TermsAndConditionsSignIn/>
            case "login":
                return <SignIn/>
            case "forgotPassword":
                return <ForgotPasswordRequestPage/>
            case "createUser":
                return <CreateUser/>
            default:
                return <SignIn/>

        }
    }
    return (
        <LoginContext.Provider value={loginService}>
            <div className={"absolute bottom-10 left-10 flex space-x-1"}>
                <div>{version}</div>
                <div>{Config.isDev ? "[Dev]" : Config.isStaging ? "[Staging]" : "[Prod]"}</div>
            </div>

            <img style={{zIndex:-1}} className={"login-bg opacity-0 sm:opacity-25 md:opacity-50 lg:opacity-75 xl:opacity-100 absolute right-0 bottom-0"} alt="bgrb" src={bgrb}/>
            <img style={{zIndex:-1}} className={"login-bg opacity-0 sm:opacity-25 md:opacity-50 lg:opacity-75 xl:opacity-100 absolute right-0 top-0"} alt="bgrt" src={bgrt}/>
            <img style={{zIndex:-1}} className={"login-bg opacity-0 sm:opacity-25 md:opacity-50 lg:opacity-75 xl:opacity-100 absolute top-1/4"} alt="bglt" src={bglt}/>

            {/*<GResponsiveTest></GResponsiveTest>*/}

            <div  className={" login-zoom-by-height m-auto"}>
                {
                    ViewCurrentRoute()
                }
            </div>

        </LoginContext.Provider>
    )
}
export default Login;
