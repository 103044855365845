import React, {useContext, useEffect, useState} from 'react';
import GBigLabel from "../GBigLabel";
import GButton from "../GButton";
import GSmallLabel from "../GSmallLabel";
import GTextInput from "../GTextInput";
import GSelect from "../GSelect";
import diagnosis_img from "../../assets/diagnosis_img_placeholder.png";
import diagnosis_overlay_img from "../../assets/diagnosis_overlay_img_placeholder.png";
import filtersIcon from "../../assets/filters-icon.svg"
import GTestView from "../GTestView";
import GResponsiveTest from "../GResponsiveTest";
import GModal from "../GModal";
import GCheckBox from "../GCheckBox";
import GTransparentButton from "../GTransparentButton";
import GTestSmallView from "../GTestSmallView";
import GMultiSelect from "../GMultiSelect";
import GBaloonDiv from "../GBaloonDiv";
import GDiagnosisFullScreen from "../GDiagnosisFullScreen";
import GDiagnosisModal from "../GDiagnosisModal";
import {GlobalContext} from "../../machines/GlobalStateMachine";
import {useActor} from "@xstate/react";
import {DeviceContext} from "../../machines/DeviceMachine";
import {BackendContext} from "../../machines/BackendMachine";
import axios from "axios";
import Config from "../../services/Config";
import bgrb from "../../assets/bgrb.svg";
import bgrt from "../../assets/bgrt.svg";
import bglt from "../../assets/bglt.svg";
import http from "http";
import JSZip from "jszip";
import * as url from "url";
import {useTranslation} from "react-i18next";
import Paho from "paho-mqtt";
import LogoSvg from "../../assets/logo_svg.svg";
import LogoNoTextSvg from "../../assets/logo_no_text.svg"
import GToggleButton from "../GToggleButton";
import ForgotPassword from "../ForgotPassword/ForgotPassword";
const Home = () => {
    const { t, i18n } = useTranslation()



    const GContext = useContext(GlobalContext)
    const [GlobalCurrentState, UpdateGlobal] = useActor(GContext);

    const DContext = useContext(DeviceContext)
    const [DeviceCurrentStatus,SetStatus] = useActor(DContext);

    const BEContext = useContext(BackendContext)
    let BackendCurrent, BESend;
    [BackendCurrent, BESend] = useActor(BEContext);

    useEffect(()=>{
        if(BackendCurrent.context.updatePassword || BackendCurrent.context.TemporaryPassword){
            UpdateGlobal({type:"SET_MUST_RESET_PASSWORD"});
        }
        BESend({type:"GET_CLINICAL_DATA"});

    },[])

    useEffect(()=>{
       //
        //setTimeout(()=>{
        let _finishedTests = []
        BackendCurrent.context.tests.forEach(test=>{
            if(test.Resolution.Status !== "PENDING"){
                _finishedTests.push(test)
            }
        })
        filterTests(_finishedTests)
        //},100)
    },[BackendCurrent.context.tests])

    const [title,] = useState(t("home.tests-history"))

    const diagnosisList =  [
        t("diagnosis.bacterial-vaginosis"),
        t("diagnosis.cytolitic-vaginitis"),
        t("diagnosis.div"),
        t("diagnosis.trichomoniasis"),
        t("diagnosis.candida-albicans"),
        t("diagnosis.candida-non-albicans"),
        t("diagnosis.vaginal-atrophy"),
        t("diagnosis.normal-vaginal-secretions"),
        t("diagnosis.other"),
    ]

    //DIAGNOSIS FULL SCREEN
    const [OverlayView] = useState(false);
    const [ViewDiagnosisFullScreen,SetDiagnosisFullScreen] = useState(null);
    const openFullScreen=(image,diagnosis)=>{
        SetDiagnosisFullScreen({
            image,
            diagnosis
        })
    }

    const [testsViewed,setTestsViewed] = useState(localStorage.getItem("testsViewed") ? JSON.parse(localStorage.getItem("testsViewed")) : false)
    const SetTestsViewed=()=>{
        localStorage.setItem("testsViewed",JSON.stringify(!testsViewed))
        setTestsViewed(!testsViewed)
    }
    //TESTS
    //--test states
    // const [tests,] = useState([
    //     // {
    //     //     TestId:Math.random().toFixed(1),
    //     //     Date:new Date(),
    //     //     Complaints:["Burning","Discharge","Dryness","Fishy Odor","Pain","Dryness","Itch",],
    //     //     PHLevel:Math.random().toFixed(1),
    //     //     Diagnosis : [
    //     //         {
    //     //             name:"Bacterial Vaginosis",
    //     //             diagnosisImg:diagnosis_img,
    //     //             diagnosisOverlayImg:diagnosis_overlay_img,
    //     //         },
    //     //         {
    //     //             name:"DIV",
    //     //             diagnosisImg:diagnosis_img,
    //     //             diagnosisOverlayImg:diagnosis_overlay_img,
    //     //         }]
    //     // },
    //     // {
    //     //     TestId:Math.random().toFixed(1),
    //     //     Date:new Date(),
    //     //     Complaints:["Burning","Discharge","Dryness","Fishy Odor","Pain","Dryness","Itch",],
    //     //     PHLevel:Math.random().toFixed(1),
    //     //     Diagnosis : [
    //     //         {
    //     //             name:"Bacterial Vaginosis",
    //     //             diagnosisImg:diagnosis_img,
    //     //             diagnosisOverlayImg:diagnosis_overlay_img,
    //     //         },
    //     //         {
    //     //             name:"DIV",
    //     //             diagnosisImg:diagnosis_img,
    //     //             diagnosisOverlayImg:diagnosis_overlay_img,
    //     //         },
    //     //         {
    //     //             name:"Trichomonisis",
    //     //             diagnosisImg:diagnosis_img,
    //     //             diagnosisOverlayImg:diagnosis_overlay_img,
    //     //         },
    //     //         {
    //     //             name:"Bacterial Vaginosis",
    //     //             diagnosisImg:diagnosis_img,
    //     //             diagnosisOverlayImg:diagnosis_overlay_img,
    //     //         },
    //     //         {
    //     //             name:"DIV",
    //     //             diagnosisImg:diagnosis_img,
    //     //             diagnosisOverlayImg:diagnosis_overlay_img,
    //     //         },
    //     //         {
    //     //             name:"Trichomonisis",
    //     //             diagnosisImg:diagnosis_img,
    //     //             diagnosisOverlayImg:diagnosis_overlay_img,
    //     //         },]
    //     // },
    // ])
    const [filteredTests,filterTests] = useState(BackendCurrent.context.tests);
    // FILTERS
    // --filter states
    const [filtersModal,SetFiltersModal] = useState(false)

    /////RESET PASSWORD
    //const [updatePasswordModal,SetUpdatePasswordModal] = useState((BackendCurrent.context.updatePassword || BackendCurrent.context.TemporaryPassword))
    const [updatePasswordModal,SetUpdatePasswordModal] = useState(false)
    /////RESET PASSWORD

    const [filterTestNumber,setFilterTestNumber] = useState(null);
    const [filterDateFrom,setFilterDateFrom] = useState("");
    const [filterDateTo,setFilterDateTo] = useState("");
    const [filterSelectedDiagnosis,setSelectedDiagnosis] = useState([]);
    // --filter functions
    const updateDiagnosisListFilter=(e)=>{
        setSelectedDiagnosis(e)
    }
    const HandleSearchButton =()=>{
        let _preFilteredTestsList = BackendCurrent.context.tests;
        // FILTER BY TEST NUMBER
        if(filterTestNumber && filterTestNumber.length > 0){
            _preFilteredTestsList = _preFilteredTestsList.filter(t=>t.TestID.indexOf(filterTestNumber) > -1)
        }
        // FILTER BY DATES
        if(filterDateFrom.length > 0){

            _preFilteredTestsList = _preFilteredTestsList.filter(t=>{
                let _date1 = (new Date(t.DateUTC).setHours(0,0,0,0))
                let _date2 = (new Date(filterDateFrom)).setHours(0, 0, 0, 0);
                let f = _date1 >= _date2
                return f;
            })
        }
        if(filterDateTo.length > 0){
            _preFilteredTestsList = _preFilteredTestsList.filter(t=>(new Date(t.DateUTC).setHours(0,0,0,0)) <= (new Date(filterDateTo)).setHours(0, 0, 0, 0))
        }

        if(filterSelectedDiagnosis.length > 0){
            let _res = [];
            _preFilteredTestsList.forEach(t=>{
               t.Diagnosis.forEach(d=>{
                   if(filterSelectedDiagnosis.filter(f=>f === d.name).length > 0){
                       if(_res.filter(r=>r.TestId === t.TestId).length === 0){
                           _res.push(t)
                       }
                   }
               })
            })
            _preFilteredTestsList = _res;
        }

        filterTests(filteredTests => [..._preFilteredTestsList])
    }

    //RESPONSIVE LOGIC
    //--responsive logic states
    const [bigTestViews,SetBigTestViews] = useState([])
    //--responsive logic functions
    const AddTestToBigViews=(index)=>{

        let _fList = bigTestViews;
        if(_fList.indexOf(index) > -1){
            _fList = _fList.filter(t=>t !== index);
            console.log(_fList)
        }
        else{
            _fList.push(index)
        }
        SetBigTestViews(bigTestViews => [..._fList])

    }

    const DateFormat=(df)=>{

        if(!df){
            return "Select a date"
        }
        const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN",
            "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"
        ];

        let res = "";
        res+=(new Date(df)).getDate();
        res+="/";
        res+=monthNames[(new Date(df)).getMonth()];
        res+="/";
        res+=(new Date(df)).getFullYear();

        return res;
    }
    return (
        <div>
            {
                BackendCurrent.context.tests.length === 0 || !testsViewed
                ?
                    <>
                        <img style={{zIndex:-1}} className={"opacity-5 md:opacity-25 lg:opacity-30 xl:opacity-40 absolute right-0 bottom-0"} alt="bgrb" src={bgrb}/>
                        <img style={{zIndex:-1}} className={"opacity-5 md:opacity-25 lg:opacity-30 xl:opacity-40 absolute right-0 top-0"} alt="bgrt" src={bgrt}/>
                        <img style={{zIndex:-1}} className={"opacity-5 md:opacity-25 lg:opacity-30 xl:opacity-40 absolute bottom-10"} alt="bglt" src={bglt}/>
                    </>
                :
                    null
            }
            {
                ViewDiagnosisFullScreen
                ?
                    <GDiagnosisModal  handleClose={()=>SetDiagnosisFullScreen(false)} >
                        <GDiagnosisFullScreen
                            className={"sm:w-31.25rem "}
                            overlay={OverlayView}
                            diagnosis={ViewDiagnosisFullScreen}
                            diagnosisImg = {ViewDiagnosisFullScreen.inputImageUrl}
                            diagnosisOverlayImg = {ViewDiagnosisFullScreen.outputImageUrl}
                        />
                    </GDiagnosisModal>
                :
                    null
            }
            {
                filtersModal
                ?
                    <div >
                        <GModal className={"pb-6.438rem"} handleClose={()=>SetFiltersModal(!filtersModal)}>

                            <div className={"lg:pr-3.125rem lg:pl-3.125rem "}>
                                {/*TITLE*/}
                                <div className={" text-center flex  justify-content-start "}>
                                    <GBigLabel className={"text-2rem font-1.125rem"}>
                                        {t("home.filter")}
                                    </GBigLabel>
                                </div>
                                <div className={"  mt-2.875rem "}>
                                    {/*Test Number*/}
                                    <div className={" mt-2.875rem "}>
                                        <GSmallLabel className={"mb-0.688rem text-0.875rem leading-0.941rem h-0.938rem"}> {t("home.filters.test-number")}</GSmallLabel>
                                        <GTextInput
                                            max={50}
                                            value={filterTestNumber}
                                            onChange={(e)=>setFilterTestNumber(e.target.value)}
                                            className={" h-3.125rem"}
                                            borderStyle={"rounded-lg border border-#1396B4 border-1px "}
                                            placeHolder={t("home.filters.placeholders.search-by-test-number")}
                                        />
                                    </div>
                                    {/*Test Date*/}
                                    <div  className={" mt-2.875rem "}>
                                        <GSmallLabel className={"mb-0.688rem text-0.875rem leading-0.941rem h-0.938rem"}>{t("home.filters.test-date")}</GSmallLabel>
                                        <div className={"flex space-x-0.375rem"}>
                                            <div className={"relative"}>
                                                <div  style={{zIndex:1}} className={"select-none absolute flex justify-content-left pl-2 align-items-center top-1 left-1 w-10 h-11 w-3/4 bg-white"}>
                                                    <GSmallLabel className={"text-xs"}>{DateFormat(filterDateFrom)}</GSmallLabel>
                                                </div>
                                                <GTextInput
                                                    value={filterDateFrom}
                                                    onChange={(e)=>setFilterDateFrom(e.target.value)}
                                                    max = {filterDateTo}
                                                    type={"date"}
                                                    className={"  h-3.125rem"}
                                                    borderStyle={"rounded-lg border border-#1396B4 border-1px "}
                                                    placeHolder={t("home.filters.placeholders.date-format")}
                                                />
                                            </div>

                                            <GSmallLabel className={" text-0.875rem mt-auto mb-auto "}>{t("home.filters.to")}</GSmallLabel>
                                            <div className={"relative"}>

                                                    <div  style={{zIndex:1}} className={"select-none absolute flex justify-content-left pl-2 align-items-center top-1 left-1 w-10 h-11 w-3/4 bg-white"}>
                                                        <GSmallLabel className={"text-xs"}>{DateFormat(filterDateTo)}</GSmallLabel>
                                                    </div>
                                                <GTextInput
                                                    value={filterDateTo}
                                                    onChange={(e)=>setFilterDateTo(e.target.value)}
                                                    min={filterDateFrom}
                                                    type={"date"}
                                                    className={"  h-3.125rem"}
                                                    borderStyle={"rounded-lg border border-#1396B4 border-1px "}
                                                    placeHolder={t("home.filters.placeholders.date-format")}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                    {/*Diagnosis*/}
                                    <div className={" mt-2.875rem "}>
                                        <GSmallLabel className={"mb-0.688rem text-0.875rem leading-0.941rem h-0.938rem"}>{t("diagnosis")}</GSmallLabel>
                                        <GMultiSelect
                                            selectedItems={filterSelectedDiagnosis}
                                            onChange={(e)=>updateDiagnosisListFilter(e)}
                                            series = {diagnosisList}
                                            className={"w-full h-3.125rem"}
                                            borderStyle={"rounded-lg border border-#1396B4 border-1px "}
                                            placeholder={t("home.filters.placeholders.search-by-diagnosis")}
                                        />
                                    </div>
                                    {/*Search*/}
                                    <div className={" mt-2.563rem "}>
                                        <div className={"mb-0.688rem  h-0.938rem"}></div>
                                        <div className={"flex justify-center"}>
                                            <div className={" w-full flex justify-start "}>
                                                <GButton
                                                    onClick={()=>HandleSearchButton()}
                                                    textColor={"text-#50C2BC"}
                                                    className={" h-3.125rem w-8.5rem bg-white border-1px border-#50C2BC rounded-2.688rem"}
                                                >
                                                    {t("home.filters.search")}
                                                </GButton>
                                            </div>

                                            <div className={"flex justify-end "}>
                                                <GTransparentButton className={" w-7.5rem h-3.125rem"}  >
                                                    {t("home.filters.clear-search")}
                                                </GTransparentButton>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </GModal>
                    </div>
                :
                    null
            }
            {
                updatePasswordModal
                ?
                    <div>
                        <GModal
                            className={"pb-6.438rem"}
                            hideClose
                            hideCloseButton
                        >
                            <ForgotPassword isModal handleClose={()=>SetUpdatePasswordModal(false)}/>
                        </GModal>
                    </div>
                :
                    null
            }
            <div className={"pt-20 mr-1.25rem ml-1.25rem md:mr-6.25rem md:ml-6.25rem"}>

                {/*Welcome label*/}
                <div className={(BackendCurrent.context.tests.length === 0 || !testsViewed) ? " mt-34vh " : "mt-6.813rem "}>
                    {
                        GlobalCurrentState.context.isFirstLogin
                        ?
                            <div>
                                <GBigLabel className={"text-1.375rem space-x-1 leading-1.316rem flex justify-content-center"}>
                                    <div> {t("home.hello")} </div>
                                    <div>,</div>
                                    <div>
                                        {

                                            BackendCurrent.context.userProfile?.FirstName
                                                ?
                                                BackendCurrent.context.userProfile?.FirstName +" "+BackendCurrent.context.userProfile?.LastName
                                                :
                                                BackendCurrent.context.profile.userId.split("@").length > 0
                                                    ?
                                                    BackendCurrent.context.profile.userId.split("@")[0]
                                                    :
                                                    BackendCurrent.context.profile.userId
                                        }
                                    </div>
                                </GBigLabel>
                                {/*<div className={"flex w-full m-auto"}>*/}
                                    <GBigLabel color={"text-#1396B4"} className={"text-2rem space-x-1 leading-1.316rem flex justify-content-center pt-3"}>
                                        <div style={{padding:"4px"}} className={"mt-auto mb-auto"}>Welcome To</div>
                                        <img  src={LogoNoTextSvg} alt="logo"/>
                                    </GBigLabel>

                                {/*</div>*/}

                            </div>


                        :
                            <GBigLabel className={"text-1.375rem space-x-1 leading-1.316rem flex justify-content-center"}>
                                <div>
                                    {t("home.welcome-back")}
                                </div>
                                <div>
                                    ,
                                </div>
                                <div>
                                    {

                                        BackendCurrent.context.userProfile?.FirstName
                                            ?
                                                BackendCurrent.context.userProfile?.FirstName +" "+BackendCurrent.context.userProfile?.LastName
                                            :
                                                BackendCurrent.context.profile.userId.split("@").length > 0
                                                ?
                                                    BackendCurrent.context.profile.userId.split("@")[0]
                                                :
                                                    BackendCurrent.context.profile.userId
                                    }
                                </div>
                            </GBigLabel>

                    }

                </div>
                {/*Start button*/}
                <div className={" mt-1.25rem"}>
                    <div className={"flex justify-content-center"}>
                        {
                            GlobalCurrentState.context.currentGlobalState === "minimized"
                            ?
                                <GButton onClick={()=>UpdateGlobal({type:"SET_ON_TOP"})} className={"h-10 w-14rem "}>
                                    {t("routing.buttons.resume")}
                                </GButton>
                            :
                                <GButton disabled={Config.NoDeviceTestStart ? false  : !DeviceCurrentStatus.context.isReady} onClick={()=>UpdateGlobal({type:"SET_IN_TEST"})} className={"h-10 w-14rem "}>
                                    + {t("routing.buttons.start-a-new-test")}
                                </GButton>
                        }

                    </div>
                </div>


                {

                        BackendCurrent.context.tests.length === 0
                        ?
                            <div>

                            </div>
                        :
                            <div>
                                {/*Title*/}
                                <div className={( testsViewed ? "  mt-6.813rem " : " absolute bottom-4 w-1/2 " ) +" h-1.688rem flex "}>
                                    <div className={"flex space-x-2 w-2/3 "}>
                                        <GBigLabel className={"  flex justify-start text-1.375rem leading-1.688rem flex justify-content-start"}>
                                            {
                                                title
                                            }
                                        </GBigLabel>
                                        <GToggleButton initial={testsViewed} textColor={" mt-auto mb-auto text-white "} onClick={()=>SetTestsViewed(!testsViewed)}/>

                                    </div>
                                    <div className={"w-1/3"}>
                                        {
                                            testsViewed
                                                ?
                                                <div onClick={()=>SetFiltersModal(!filtersModal)} className={"xl:hidden flex flex justify-end cursor-pointer"}>
                                                    <img className={""} src={filtersIcon} alt={"filters"}/>
                                                </div>
                                                :
                                                null
                                        }
                                    </div>


                                </div>
                                {
                                    testsViewed
                                    ?
                                        <>
                                            <div className={"mt-1.125rem flex flex-wrap xl:hidden "}>
                                                {
                                                    filterTestNumber ? <GBaloonDiv handleClose={()=>setFilterTestNumber(null)}>{filterTestNumber}</GBaloonDiv> : null
                                                }
                                                {
                                                    filterDateFrom && !filterDateTo ? <GBaloonDiv>{t("home.filters.test-date")} : {t("home.filters.from")} {DateFormat(filterDateFrom)} </GBaloonDiv> : null
                                                }
                                                {
                                                    filterDateTo && !filterDateFrom ? <GBaloonDiv>{t("home.filters.test-date")} : {t("home.filters.to")} {DateFormat(filterDateTo)} </GBaloonDiv> : null
                                                }
                                                {
                                                    filterDateTo && filterDateFrom ? <GBaloonDiv>{t("home.filters.test-date")} : {DateFormat(filterDateFrom)}  {t("home.filters.to")} {DateFormat(filterDateTo)}</GBaloonDiv> : null
                                                }
                                                {
                                                    filterSelectedDiagnosis ?
                                                        filterSelectedDiagnosis.map((f,k)=>
                                                            <GBaloonDiv key={"gb-"+k}>{f}</GBaloonDiv>
                                                        ):null
                                                }
                                            </div>
                                            {/*Filters Panel*/}
                                            <div className={"hidden xl:flex space-x-1.25rem  mt-3.25rem flex"}>
                                                {/*Test Number*/}
                                                <div>
                                                    <GSmallLabel className={"mb-0.688rem text-0.875rem leading-0.941rem h-0.938rem"}>{t("test.test-number")}</GSmallLabel>
                                                    <GTextInput
                                                        max={50}
                                                        value={filterTestNumber}
                                                        onChange={(e)=>setFilterTestNumber(e.target.value)}
                                                        className={"w-18.438rem h-3.125rem text-0.75rem"}
                                                        borderStyle={"rounded-lg border border-#1396B4 border-1px "}
                                                        placeHolder={t("home.filters.placeholders.search-by-test-number")}
                                                    />
                                                </div>
                                                {/*Test Date*/}
                                                <div >
                                                    <GSmallLabel className={"mb-0.688rem text-0.875rem leading-0.941rem h-0.938rem"}>{t("home.filters.test-date")}</GSmallLabel>
                                                    <div className={"flex space-x-0.375rem"}>
                                                        <div className={"relative "}>
                                                            <div  style={{zIndex:1}} className={"select-none absolute flex justify-content-left pl-2 align-items-center top-1 left-1 w-10 h-11 w-3/4 bg-white"}>
                                                                <GSmallLabel className={"text-xs"}>{DateFormat(filterDateFrom)}</GSmallLabel>
                                                            </div>
                                                            <GTextInput
                                                                value={filterDateFrom}
                                                                onChange={(e)=>setFilterDateFrom(e.target.value)}
                                                                max = {filterDateTo}
                                                                type={"date"}
                                                                className={" h-3.125rem text-0.75rem  "}
                                                                borderStyle={"rounded-lg border border-#1396B4 border-1px "}
                                                                // placeHolder={"dd/mm/yy"}
                                                            />

                                                        </div>

                                                        <GSmallLabel className={" text-0.875rem mt-auto mb-auto "}>{t("home.filters.to")}</GSmallLabel>
                                                        <div className={"relative "}>
                                                            <div style={{zIndex:1}} className={"absolute select-none  flex justify-content-left pl-2 align-items-center top-1 left-1 w-10 h-11 w-3/4 bg-white"}>
                                                                <GSmallLabel className={"text-xs"}>{DateFormat(filterDateTo)}</GSmallLabel>
                                                            </div>
                                                            <GTextInput
                                                                value={filterDateTo}
                                                                onChange={(e)=>setFilterDateTo(e.target.value)}
                                                                min = {filterDateFrom}
                                                                type={"date"}
                                                                className={"  h-3.125rem text-0.75rem "}
                                                                borderStyle={"rounded-lg border border-#1396B4 border-1px "}
                                                                // placeHolder={"dd/mm/yy"}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/*Diagnosis*/}
                                                <div>
                                                    <GSmallLabel className={"mb-0.688rem text-0.875rem leading-0.941rem h-0.938rem"}>{t("diagnosis")}</GSmallLabel>
                                                    <GMultiSelect
                                                        selectedItems={filterSelectedDiagnosis}
                                                        onChange={(e)=>updateDiagnosisListFilter(e)}
                                                        series = {diagnosisList}
                                                        className={"w-18.438rem h-3.125rem text-0.75rem"}
                                                        borderStyle={"rounded-lg border border-#1396B4 border-1px "}
                                                        placeholder={t("home.filters.all")}
                                                    />
                                                </div>
                                                {/*Search*/}
                                                <div>
                                                    <div className={"mb-0.688rem  h-0.938rem"}></div>
                                                    <div className={"flex "}>
                                                        <GButton
                                                            onClick={()=>HandleSearchButton()}
                                                            customStyle
                                                            textColor={"text-#50C2BC"}
                                                            className={"h-3.125rem w-8.5rem bg-white border-1px border-#50C2BC rounded-2.688rem"}
                                                        >
                                                            {t("home.filters.search")}
                                                        </GButton>
                                                    </div>
                                                </div>

                                            </div>
                                            {/*Tests View*/}

                                            <div className={"mt-2.25rem pb-24"}>
                                                {
                                                    filteredTests.length < BackendCurrent.context.tests.length
                                                        ?
                                                        <GSmallLabel className={"mb-0.688rem text-0.875rem leading-0.941rem h-0.938rem"}>{t("home.filters.showing-all-tests")}</GSmallLabel>
                                                        :
                                                        <div className={"flex  space-x-1 mb-0.688rem "}>
                                                            <GSmallLabel className={" text-md leading-0.941rem h-0.938rem"}>  {t("home.filters.found")}  </GSmallLabel>
                                                            <GSmallLabel bold className={" text-md leading-0.941rem h-0.938rem"}>{filteredTests.length}</GSmallLabel>
                                                            <GSmallLabel className={" text-md leading-0.941rem h-0.938rem"}> {t("home.filters.results")}</GSmallLabel>
                                                        </div>

                                                }

                                                {
                                                    filteredTests.map(
                                                        (test,index)=>
                                                            <div className={"space-y-4 "} key={"ft"+index}>
                                                                <div  className={"sm:hidden  space-y-1.25rem shadow-gyniTest rounded-2xl "}>
                                                                    {
                                                                        bigTestViews.filter(btv => btv === index).length > 0
                                                                            ?
                                                                            <GTestView
                                                                                onClick={()=>AddTestToBigViews(index)}
                                                                                paddings={"pl-2.5rem pr-2.5rem pt-1.75rem pb-1.75rem mb-8"}
                                                                                smallBody
                                                                                smallHeader
                                                                                handleFullScreen={openFullScreen}
                                                                                TestId={test.TestID}
                                                                                TestDate={test.Resolution.DateUTC}
                                                                                Diagnosis={test.AlgoResult ? test.AlgoResult.Diagnosis ? test.AlgoResult.Diagnosis : []  : []}
                                                                                PatientYearOfBirth={test.PatientYearOfBirth}
                                                                                Complaints={test.Complaints}
                                                                                PHLevel={test.AlgoResult ? test.AlgoResult.PH : 0}

                                                                            />
                                                                            :
                                                                            <GTestSmallView
                                                                                onClick={()=>AddTestToBigViews(index)}
                                                                                paddings={"pl-2.5rem pr-2.5rem pt-1.75rem pb-1.75rem mb-8"}
                                                                                smallBody
                                                                                smallHeader
                                                                                handleFullScreen={openFullScreen}
                                                                                TestId={test.TestID}
                                                                                TestDate={test.Resolution.DateUTC}
                                                                                Diagnosis={test.AlgoResult ? test.AlgoResult.Diagnosis ? test.AlgoResult.Diagnosis : []  : []}
                                                                                PatientYearOfBirth={test.PatientYearOfBirth}
                                                                                Complaints={test.Complaints}
                                                                                PHLevel={test.AlgoResult ? test.AlgoResult.PH : 0}

                                                                            />
                                                                    }
                                                                </div>
                                                                <div key={index*3} className={"hidden sm:block space-y-1.25rem shadow-gyniTest rounded-2xl "}>

                                                                    <GTestView
                                                                        onClick={() => AddTestToBigViews(index)}
                                                                        paddings={"pl-2.5rem pr-2.5rem pt-1.75rem pb-1.75rem"}
                                                                        smallBody
                                                                        smallHeader
                                                                        handleFullScreen={openFullScreen}
                                                                        TestId={test.TestID}
                                                                        TestDate={test.Resolution.DateUTC}
                                                                        Diagnosis={test.AlgoResult ? test.AlgoResult.Diagnosis ? test.AlgoResult.Diagnosis : []  : []}
                                                                        PatientYearOfBirth={test.PatientYearOfBirth}
                                                                        Complaints={test.Complaints}
                                                                        PHLevel={test.AlgoResult ? test.AlgoResult.PH : 0}

                                                                    />

                                                                </div>
                                                            </div>

                                                    )
                                                }
                                            </div>
                                        </>
                                    :
                                        null
                                }

                            </div>

                }

            </div>
        </div>

    )
};
export default Home;

