import React, {useContext, useEffect, useState} from 'react';
import {RouteContext} from "../../machines/RoutingMachine";
import {useActor} from "@xstate/react";
import Home from "../Home/Home";
import Test from "../Test/Test";
import {GlobalContext} from "../../machines/GlobalStateMachine";
import Statistics from "../Statistics/Statistics";
import ClinicalDetails from "../../pages/clinicaldetails";
import ClinicDetails from "../ClinicDetails/ClinicDetails";
import {BackendContext} from "../../machines/BackendMachine";
import TutorialVideo from "../TutorialVideo";
import ConnectScanner from "../ConnectScanner";
import TermsAndConditions from "../TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "../TermsAndConditions/PrivacyPolicy";
import {useTranslation} from "react-i18next";
import LanguageSelector from "../LanguageSelector";
import ResetPassword from "../ResetPassword";
import Admin from "../Admin/Admin";
import ContactUs from "../ContactUs/ContactUs";
import EmailPopup from "../EmailPopup";
import {useIdleTimer} from "react-idle-timer";
import MustResetPassword from "../MustResetPassword";

const AppRouter = (props) => {
    const { t, i18n } = useTranslation()
    const BEContext = useContext(BackendContext)
    let BackendCurrent, BESend;
    [BackendCurrent, BESend] = useActor(BEContext);

    const RContext = useContext(RouteContext)
    const [RouteCurrentState, Navigate] = useActor(RContext);

    const GContext = useContext(GlobalContext)
    const [GlobalCurrentState, UpdateGlobal] = useActor(GContext);

    const handleOnIdle = event => {
        console.log('user is idle', event)
        console.log('last active', getLastActiveTime())
        BESend({ type : "REQUEST_LOGOUT" })
    }

    const handleOnActive = event => {
        console.log('user is active', event)
        console.log('time remaining', getRemainingTime())
    }

    const handleOnAction = event => {
        console.log('user did something', event)
    }


    const { getRemainingTime, getLastActiveTime } = useIdleTimer({
        timeout: 1000 * 60 * 60,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        onAction: handleOnAction,
        debounce: 500
    })

    useEffect(()=>{
        let storedUser = localStorage.getItem(BackendCurrent.context.profile.userId);
        if(storedUser && storedUser !== ""){
            storedUser = JSON.parse(storedUser)
        }
        if(storedUser === true){
            UpdateGlobal({type:"SET_FIRST_LOGIN",isFirstLogin:false})
        }else{
            UpdateGlobal({type:"SET_TUTORIAL_VIDEO"});
            localStorage.setItem(BackendCurrent.context.profile.userId,"true")
            UpdateGlobal({type:"SET_FIRST_LOGIN",isFirstLogin:true})
        }
    },[])

    const ViewCurrentRoute =()=>{
        switch (RouteCurrentState.context.currentRoute){
            case "admin":
                return <Admin/>
            case "home":
                return <Home/>
            case "statistics":
                return <Statistics/>
            case "clinic":
                return <ClinicDetails/>
            case "termsAndConditions":
                return <TermsAndConditions/>
            case "contactUs":
                return <ContactUs/>
            case "privacyAndPolicy":
                return <PrivacyPolicy/>

        }
    }

    return (
        <div>
            {
                GlobalCurrentState.context.currentGlobalState === "onTop"
                ?
                    <Test/>
                :
                    null
            }
            {
                GlobalCurrentState.context.currentGlobalState === "tutorialVideo"
                    ?
                    <TutorialVideo/>
                    :
                    null
            }
            {
                GlobalCurrentState.context.currentGlobalState === "emailPopup"
                    ?
                    <EmailPopup/>
                    :
                    null
            }
            {
                GlobalCurrentState.context.currentGlobalState === "languageSelector"
                    ?
                    <LanguageSelector/>
                    :
                    null
            }
            {
                GlobalCurrentState.context.currentGlobalState === "mustResetPassword"
                    ?
                    <MustResetPassword/>
                    :
                    null
            }
            {
                GlobalCurrentState.context.currentGlobalState === "resetPassword"
                    ?
                    <ResetPassword/>
                    :
                    null
            }
            {
                GlobalCurrentState.context.currentGlobalState === "connectToScanner"
                    ?
                    <ConnectScanner/>
                    :
                    null
            }
            {
                ViewCurrentRoute()
            }
        </div>
    )
};
export default AppRouter;

