export default function Config() {}

//PRE_RELEASE_CONFIG
Config.isDev  = true;
Config.isStaging = false;
//DEBUGGING
Config.Debug = false;
Config.NoDeviceTestStart = false;
Config.NoUploadTestData = false;

//REGION BACKEND
Config.globalUrl = Config.isDev ? 'https://api-dev.gyntools.com'  : Config.isStaging ? 'https://api-staging.gyntools.com' : 'https://api-ct.gyntools.com';
Config.globalUrlForgot = Config.isDev ? 'https://dev.gyntools.com'  : Config.isStaging ? 'https://staging.gyntools.com' : 'https://api-ct.gyntools.com';
Config.globalPort = '443';
Config.UserID = '';
Config.deviceName = '';
Config.region = "eu-central-1"
Config.deviceEndpoint = "a1wtn96vdxdogt-ats.iot."+Config.region+".amazonaws.com";
Config.accessKeyId = "";
Config.secretAccessKey = "";
Config.token = {
    headers: {
      'Authorization': ''
    }
}
Config.hideSiteAdmin = false;
Config.role = []
Config.getUserDataFromCache = true;
//ENDREGION BACKEND

//LOGS
Config.logLevel = 1;
//END LOGS

//UI CONFIG
Config.subStatusEnabled = false;
Config.closeAlertEnabled= false;
Config.dontShowLabResults=false;
Config.getImagesFromZip = false;
//END UI CONFIG
Config.DevReCaptcha = "6LfeEEMfAAAAAIjnwGTc9a4ouoK85SyB5WQbgKzq"
Config.StagingReCaptcha = "6LcoEkMfAAAAADUBmUdTTu7FhAQi_p8VUOOeKC-u"
Config.ReCaptcha = ""
Config.FailedLoginCount = 0;